<template>
  <div>
    <el-breadcrumb separator="/" class="dashboard-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/dashboard-custom/category' }">全部分类</el-breadcrumb-item>
      <el-breadcrumb-item>{{ currentCategory }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="loading-pre-container" v-loading="loading">
      <div class="dashboard-item" v-for="item in dashboardList" :key="item.uuid" @click="toDashboradDetail(item)">
        <div class="dashboard-item-container">
          <div class="image-container container">
            <img :src="dashboardSVG" alt="" />
          </div>

          <div class="dashboard-item-handle" @click.stop>
            <el-dropdown trigger="click" size="small">
              <div class="icon">
                <i class="el-icon-more-outline"></i>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="edit(item)">修改</el-dropdown-item>
                <el-dropdown-item @click.native="deleteItem(item)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <p class="dashboard-item-text">{{ item.name }}</p>
      </div>

      <a-empty v-if="dashboardList.length < 1" />
    </div>

    <el-dialog title="编辑Dashboard" :visible.sync="dialogVisible">
      <el-form :model="form" label-position="top" ref="form" size="small">
        <el-form-item
          label="Dashboard名称"
          prop="name"
          :rules="[{ required: true, message: '请输入Dashboard名称', trigger: 'blur' }]"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item
          label="Dashboard分类"
          prop="categoryUUID"
          :rules="[{ required: true, message: '请选择Dashboard类别', trigger: 'change' }]"
        >
          <el-select v-model="form.categoryUUID" :loading="categoryLoading" filterable style="width: 100%;">
            <el-option v-for="item in categories" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" size="small">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCategories, getDashboardList, updateDashboard, deleteDashboard } from "api/dashboard.js";
import dashboardSVG from "@/assets/dashboard-item.svg";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      dashboardSVG,
      dashboardList: [],
      loading: false,
      categoryLoading: false,
      dialogVisible: false,
      form: {},
      categories: []
    };
  },

  methods: {
    getCategories() {
      this.categoryLoading = true;
      getCategories().then(response => {
        this.categoryLoading = false;
        if (response.code === 0) {
          this.categories = response.data;
        }
      });
    },

    getDashboardList() {
      this.loading = true;
      getDashboardList(this.category).then(response => {
        this.loading = false;
        if (response.code === 0) this.dashboardList = response.data;
      });
    },

    edit(item) {
      this.getCategories();
      this.dialogVisible = true;
      this.form = cloneDeep(item);
    },

    deleteItem(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"))
        .then(() => {
          deleteDashboard(item.uuid).then(response => {
            if (response.code === 0) {
              this.getDashboardList();
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateDashboard(this.form).then(response => {
            if (response.code === 0) {
              this.dialogVisible = false;
              this.getDashboardList();
            }
          });
        }
      });
    },

    toDashboradDetail(item) {
      this.$router.push({
        path: `/dashboard-custom/dashboard/${item.uuid}/detail`
      });
    }
  },

  mounted() {
    this.getDashboardList();
  },

  computed: {
    category() {
      return this.$route.params.category;
    },

    currentCategory() {
      return this.$route.query.name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboard.scss";
</style>
